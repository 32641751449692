import React from 'react'
import Layout from "../components/Layout"
import { graphql, useStaticQuery } from 'gatsby'
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import { Script } from "gatsby"
import { Link } from "gatsby"
import { navigate } from "gatsby"


import { convertToBgImage } from "gbimage-bridge"




const GbiBridged = () => {
  const { cImage1, cImage2, cImage3, cImage4, cImage5, cImage6, cImage7, cImage8, cImage9, cImage10, cImage11, cImage12, cImage13, cImage14, cImage15, cImage16 } = useStaticQuery(
    graphql`
      query {
        cImage1: file(relativePath: { eq: "gallery/lamia-audi-r8-1.jpg" }) {
          childImageSharp {
            gatsbyImageData(
              width: 2698
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        cImage2: file(relativePath: { eq: "gallery/lamia-audi-r8-2.jpeg" }) {
          childImageSharp {
            gatsbyImageData(
              width: 2698
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        cImage3: file(relativePath: { eq: "gallery/lamiaautowrap-audi-a4-3.jpg" }) {
          childImageSharp {
            gatsbyImageData(
              width: 2698
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        cImage4: file(relativePath: { eq: "gallery/lamiaautowrap-audi-a6-avant.jpg" }) {
          childImageSharp {
            gatsbyImageData(
              width: 2698
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        cImage5: file(relativePath: { eq: "gallery/lamiaautowrap-audi-a7-2.jpg" }) {
          childImageSharp {
            gatsbyImageData(
              width: 2698
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        cImage6: file(relativePath: { eq: "gallery/lamiaautowrap-bmw-x4-2.jpg" }) {
          childImageSharp {
            gatsbyImageData(
              width: 2698
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        cImage7: file(relativePath: { eq: "gallery/lamiaautowrap-bmw420-1.jpg" }) {
          childImageSharp {
            gatsbyImageData(
              width: 2698
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        cImage8: file(relativePath: { eq: "gallery/lamiaautowrap-lanstrafiken-2.jpg" }) {
          childImageSharp {
            gatsbyImageData(
              width: 2698
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        cImage9: file(relativePath: { eq: "gallery/lamiaautowrap-mercedes-cla.jpg" }) {
          childImageSharp {
            gatsbyImageData(
              width: 2698
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        cImage10: file(relativePath: { eq: "gallery/lamiaautowrap-mg-2.jpg" }) {
          childImageSharp {
            gatsbyImageData(
              width: 2698
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        cImage11: file(relativePath: { eq: "gallery/lamiaautowrap-orange-a6.jpg" }) {
          childImageSharp {
            gatsbyImageData(
              width: 2698
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        cImage12: file(relativePath: { eq: "gallery/lamiaautowrap-renault-zoe-1.jpg" }) {
          childImageSharp {
            gatsbyImageData(
              width: 2698
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        cImage13: file(relativePath: { eq: "gallery/lamiaautowrap-volvo-v60.jpg" }) {
          childImageSharp {
            gatsbyImageData(
              width: 2698
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        cImage14: file(relativePath: { eq: "gallery/lamiaautowrap-vw-id3-1.jpg" }) {
          childImageSharp {
            gatsbyImageData(
              width: 2698
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        cImage15: file(relativePath: { eq: "gallery/lamiaautowrap-white-tesla-2.jpg" }) {
          childImageSharp {
            gatsbyImageData(
              width: 2698
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        cImage16: file(relativePath: { eq: "gallery/lamiaautowrap-toyota-aygo-1.jpg" }) {
          childImageSharp {
            gatsbyImageData(
              width: 2698
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        
        
      }
      
    `
  )
  const image = getImage(cImage1)
  const image2 = getImage(cImage2)
  const image3 = getImage(cImage3)
  const image4 = getImage(cImage4)
  const image5 = getImage(cImage5)
  const image6 = getImage(cImage6)
  const image7 = getImage(cImage7)
  const image8 = getImage(cImage8)
  const image9 = getImage(cImage9)
  const image10 = getImage(cImage10)
  const image11 = getImage(cImage11)
  const image12 = getImage(cImage12)
  const image13 = getImage(cImage13)
  const image14 = getImage(cImage14)
  const image15 = getImage(cImage15)
  const image16 = getImage(cImage16)


  return (
    
    <Layout>
 
    

 
    <main>
      <div id="content" className="subp gallery">
      <section className="row text-center headings">
      <h1 className='h1-heading'>Referenser</h1>
      <span>Här kan ni se ett urval av våra senaste projekt.</span>
 

      </section>
     
      <section className="row box">

    
      <div className="col col-12 col-md-6">
        <GatsbyImage image={image} alt={"image"} className="content-image" width="1000"/>
      </div>

      <div className="col col-12 col-md-6">
        <GatsbyImage image={image2} alt={"image2"} className="content-image" width="1000"/>

      </div>

      <div className="col col-12 col-md-6">
        <GatsbyImage image={image3} alt={"image3"} className="content-image" width="1000"/>

      </div>

      <div className="col col-12 col-md-6">
        <GatsbyImage image={image4} alt={"image4"} className="content-image" width="1000"/>

      </div>

      <div className="col col-12 col-md-6">
        <GatsbyImage image={image5} alt={"image5"} className="content-image" width="1000"/>

      </div>

      <div className="col col-12 col-md-6">
        <GatsbyImage image={image6} alt={"image6"} className="content-image" width="1000"/>

      </div>
      <div className="col col-12 col-md-6">
        <GatsbyImage image={image7} alt={"image7"} className="content-image" width="1000"/>

      </div>
      <div className="col col-12 col-md-6">
        <GatsbyImage image={image8} alt={"image8"} className="content-image" width="1000"/>

      </div>
      <div className="col col-12 col-md-6">
        <GatsbyImage image={image9} alt={"image9"} className="content-image" width="1000"/>

      </div>
      <div className="col col-12 col-md-6">
        <GatsbyImage image={image10} alt={"image10"} className="content-image" width="1000"/>

      </div>
      <div className="col col-12 col-md-6">
        <GatsbyImage image={image11} alt={"image11"} className="content-image" width="1000"/>

      </div>
      <div className="col col-12 col-md-6">
        <GatsbyImage image={image12} alt={"image12"} className="content-image" width="1000"/>

      </div>
      <div className="col col-12 col-md-6">
        <GatsbyImage image={image13} alt={"image13"} className="content-image" width="1000"/>

      </div>
      <div className="col col-12 col-md-6">
        <GatsbyImage image={image14} alt={"image14"} className="content-image" width="1000"/>

      </div>
      <div className="col col-12 col-md-6">
        <GatsbyImage image={image15} alt={"image15"} className="content-image" width="1000"/>

      </div>
      <div className="col col-12 col-md-6">
        <GatsbyImage image={image16} alt={"image16"} className="content-image" width="1000"/>

      </div>

      </section>
      
     

     </div>
     </main>
   

  </Layout>


  )
}

export default GbiBridged


